<template>
  <div class="search">
    <div class="searchtop">
      <div class="searchc">
        <van-search
          v-model="value"
          @clear="clear"
          @update:model-value="onChange"
          placeholder="请输入搜索关键词"
        />
      </div>
      <div class="clols" @click="onClick">
        <span>{{ btn }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";
import {  useRouter } from "vue-router";
import { orderlist_api } from "../store/apis";
export default {
  setup() {
      const router = useRouter();
    const params = {
      mixture: "",
    };
    const data = ref({
      count: 0,
      current: 0,
      list: [],
      total: 0,
    });

    const value = ref("");
    const btn = ref("取消");
    const onChange = () => {
      btn.value = "搜索";
    };
    const clear = () => {
      btn.value = "取消";
    };
    const onClick = () => {
      if (btn.value === "取消") {
      router.go(-1);
      } else {
        params.mixture = value.value;
        orderlist(params);
      }
    };
    const orderlist = (params) => {
      orderlist_api(params).then((resp) => {
        console.log(resp);
        if (resp.code === 0) {
          data.value.count = resp.data.count;
          data.value.total = resp.data.total;
          data.value.current = resp.data.current;
          resp.data.list.forEach((item) => {
            data.value.list.push(item);
          });
        }
      });
    };
    return { value, onChange, btn, clear, onClick };
  },
};
</script>


<style lang="scss" >
.searchtop {
  display: flex;
  background-color: #fff;
  .searchc {
    width: 100%;
  }
  .clols {
    width: 70px;
    text-align: center;
    line-height: 50px;
    color: #576b95;
  }
}
</style>